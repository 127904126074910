/* You can add global styles to this file, and also import other style files */

//@import "./app/_variables_mixins.scss";

@use "./app/variables_mixins" as *;


@use '@material/typography/mdc-typography';
@use '@material/typography';

@use '@material/button/mdc-button';
@use '@material/button';

@use '@material/card/mdc-card';
@use '@material/card';

@use '@material/checkbox/mdc-checkbox';
@use '@material/checkbox';

@use '@material/data-table/mdc-data-table';
@use '@material/data-table';

@use '@material/dialog/mdc-dialog';
@use '@material/dialog';

@use '@material/fab/mdc-fab';
@use '@material/fab';

@use '@material/form-field/mdc-form-field';
@use '@material/form-field/_index' as form-field;

@use '@material/icon-button/mdc-icon-button';
@use '@material/icon-button/_index' as icon-button;

@use '@material/list/mdc-list';
@use '@material/list';

@use '@material/radio/mdc-radio';
@use '@material/radio';

@use '@material/select/mdc-select';
@use '@material/select/_index' as select;
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/menu/mdc-menu';

@use '@material/snackbar/mdc-snackbar';
@use '@material/snackbar/_index' as snackbar;

@use '@material/tab-bar/mdc-tab-bar';
@use '@material/tab-bar';
@use '@material/tab-scroller/mdc-tab-scroller';
@use '@material/tab-scroller';
@use '@material/tab-indicator/mdc-tab-indicator';
@use '@material/tab-indicator';
@use '@material/tab/mdc-tab';
@use '@material/tab';

@use '@material/textfield/mdc-text-field';
@use '@material/textfield/_index' as textfield;

@use "@angular-mdc/theme/material";

* {
    box-sizing: border-box;
}

html, body { height: 100%; }

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: rgba(0,0,0,0.87);
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

a {
    color: mix(black, $mdc-theme-primary, 50%);
}

.p__body {
    margin: 0 0 1em 0;
}

.mdc-card {
    padding: 20px;
    margin: 20px 0;
}

.mdc-dialog__actions button {
    margin-left: 10px; // mellomrom mellom knapper i mdc-dialoger
}

.mdc-text-field {
    margin: 1em 0;
    .layout--row &:not(:last-child) {
        margin-right: 1em;
    }
    &.mdc-text-field--outlined {
        background-color: #fff;
    }
    &.w50 {
        width: 50%;
    }
}

.mdc-select {
    display: inline-block;
    margin: 1em 0;
    &:not(:last-child) {
        margin-right: 1em;
    }
    &.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__anchor {
        background-color: #fff;
        width: 100%;
    }
}



.mdc-tab--active {
    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-color: mix(black, $mdc-theme-primary, 50%);
    }
    .mdc-tab__icon,
    .mdc-tab__text-label {
        color: mix(black, $mdc-theme-primary, 50%);
    }
}

.mdc-button--raised.button--warning {
    background-color: orange;
}

.mdc-button.button-no-bg:not(:disabled),
.mdc-icon-button.button-no-bg:not(:disabled) {
    @include btn--lys();
}

.mdc-button.button--outlined {
    border: 2px solid mix(black, $mdc-theme-primary, 50%);
    color: mix(black, $mdc-theme-primary, 50%);
}

.mdc-button.button--secondary {
    @include button.filled-accessible($mdc-theme-secondary);
}


.w100 {
    &.mdc-text-field,
    &.mdc-select {
        width: 100%;
    }
}
.w50 {
    &.mdc-text-field,
    &.mdc-select {
        width: 50%;
    }
}.w25 {
    &.mdc-text-field,
    &.mdc-select {
        width: 25%;
    }
}
.w20 {
    &.mdc-text-field,
    &.mdc-select {
        width: 20%;
    }
}

.radio--vertical {
    display: flex;
    flex-direction: column;
}

.mdc-checkbox {
    @include checkbox.ink-color(#ffffff);
}

.mdc-snackbar--success {
    @include snackbar.fill-color(#AEFFC9);
    @include snackbar.label-ink-color(#000);
}


.content-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    @include md() {
        flex-direction: row;
    }
}

.sok-form {
  display: flex;
  align-items: center;
}

.search-field {
    margin-right: 10px;
}

.backlink {
    align-items: center;
    display: inline-flex;
    line-height: 1;
    margin-bottom: 2em;
    margin-left: 16px;
    text-decoration: none;
    .material-icons {
        margin-right: 0.25em;
    }
}

.list-item-text {
    flex: 1 1 100%;
    &--secondary {
        flex-basis: 30%;
    }
}

.text__medium-emphasis {
    color: rgba(0,0,0,0.6);
}

.content-list .mdc-list-item:hover {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.list-heading {
    color: rgba(0,0,0,0.6);
    display: flex;
    font-size: 0.8em;
    padding: 8px 16px;
    text-transform: uppercase;
}

.no-match {
    display: flex;
    justify-content: center;
}