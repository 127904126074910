
@use '@material/theme' with (
	$primary: #60dbfc,
	$secondary: #2d2d2d,
	$background: #fff,
  );

/*---------- VARIABLES ---------------------------------*/

$mdc-theme-primary: #60dbfc; // primary color
$mdc-theme-secondary: #2d2d2d; // secondary color

$mdc-primary-on-surface: #0060aa; // primary 900

$bk-red: #AA273E;

$bk-secondary-50: #D8F2FE;
$bk-secondary-100: #B7DBE5;


$screen-sm: 38em;
$screen-md: 50em;
$screen-lg: 70em;


/*---------- MIXINS ------------------------------------*/

@mixin sm() {
	@media all and (min-width: #{$screen-sm}) {
		@content;
	}
}
@mixin md() {
	@media all and (min-width: #{$screen-md}) {
		@content;
	}
}
@mixin lg() {
	@media all and (min-width: #{$screen-lg}) {
		@content;
	}
}


// Utilities

@mixin btn--lys {
	color: mix(black, $mdc-theme-primary, 50%);
}

